(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/selectors/assets/javascripts/select-draws.js') >= 0) return;  svs.modules.push('/components/tipsen/selectors/assets/javascripts/select-draws.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createSelector
} = RTK;
const {
  selectDrawProductIds
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectAll
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  selectBetEvents,
  selectSportEvents,
  selectLeagues
} = svs.components.sportinfo.matchInfoRedux.selectors;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const selectAllDraws = state => state.Draws || {
  entities: {}
};
const makeSelectDrawsByDrawNumber = (productId, drawNumbers) => createSelector(selectAllDraws, draws => drawNumbers.map(drawNumber => draws.entities["".concat(productId, "_").concat(drawNumber)]));
const selectDrawsByProduct = createSelector([selectDrawProductIds, selectAll], (productIds, draws) => draws.filter(draw => productIds.includes(draw.productId)));
const selectCurrentDrawsByProduct = createSelector([selectDrawProductIds, selectAll], (productIds, draws) => {
  const validStates = [DrawState.Defined, DrawState.Opened];
  return draws.filter(draw => !draws.isLite && productIds.includes(draw.productId) && validStates.includes(draw.drawStateId)).filter(v => v);
});
const makeSelectBetEventsFromDraw = productDrawId => createSelector(selectAllDraws, selectBetEvents, (draws, betEvents) => {
  const draw = draws.entities[productDrawId] || {
    drawEvents: []
  };
  const {
    productId,
    drawNumber
  } = draw;
  return draw.drawEvents.map(_ref => {
    let {
      eventNumber
    } = _ref;
    return betEvents["".concat(productId, "_").concat(drawNumber, "_").concat(eventNumber)];
  });
});
const makeSelectLeaguesFromDraw = productDrawId => createSelector(selectSportEvents, selectAllDraws, selectLeagues, (sportEvents, draws, leagues) => {
  const draw = draws.entities[productDrawId] || {
    drawEvents: []
  };
  return draw.drawEvents.map(_ref2 => {
    let {
      matchId
    } = _ref2;
    if (matchId && sportEvents[matchId]) {
      var _sportEvents$matchId;
      return leagues[(_sportEvents$matchId = sportEvents[matchId]) === null || _sportEvents$matchId === void 0 ? void 0 : _sportEvents$matchId.leagueId];
    }
    return undefined;
  }).filter(Boolean);
});
const makeSelectLeaguesAccumulatedFromDraw = productDrawId => createSelector(makeSelectLeaguesFromDraw(productDrawId), leagues => Object.entries(
leagues.reduce((leagueReducer, _ref3) => {
  var _leagueReducer$id$ord, _leagueReducer$id, _leagueReducer$id2;
  let {
    id,
    uniqueLeagueName,
    name
  } = _ref3;
  const currentOrder = (_leagueReducer$id$ord = (_leagueReducer$id = leagueReducer[id]) === null || _leagueReducer$id === void 0 ? void 0 : _leagueReducer$id.order) !== null && _leagueReducer$id$ord !== void 0 ? _leagueReducer$id$ord : Object.keys(leagueReducer).length + 1;
  return _objectSpread(_objectSpread({}, leagueReducer), {}, {
    [id]: {
      id,
      name,
      uniqueLeagueName,
      occurrences: ((_leagueReducer$id2 = leagueReducer[id]) !== null && _leagueReducer$id2 !== void 0 ? _leagueReducer$id2 : 0) + 1,
      order: currentOrder
    }
  });
}, {})).map(_ref4 => {
  let [key, data] = _ref4;
  return _objectSpread({
    key
  }, data);
}).sort((a, b) => a.order - b.order));
const makeSelectBetEventParticipantsFromDraw = (productId, drawNumber) => createSelector(makeSelectBetEventsFromDraw("".concat(productId, "_").concat(drawNumber)), state => state.Participants, (betEvents, participants) => {
  if (!productId || !drawNumber || !betEvents) {
    return [];
  }
  return betEvents.map(event => _objectSpread(_objectSpread({}, event), {}, {
    homeTeam: participants[event.participants[0]],
    awayTeam: participants[event.participants[1]]
  }));
});
setGlobal('svs.components.tipsen.selectors', {
  selectAllDraws,
  selectDrawsByProduct,
  selectCurrentDrawsByProduct,
  makeSelectBetEventsFromDraw,
  makeSelectLeaguesFromDraw,
  makeSelectLeaguesAccumulatedFromDraw,
  makeSelectDrawsByDrawNumber,
  makeSelectBetEventParticipantsFromDraw
});

 })(window);