(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/selectors/assets/javascripts/select-settings.js') >= 0) return;  svs.modules.push('/components/tipsen/selectors/assets/javascripts/select-settings.js');
"use strict";


const {
  TipsinfoTypes
} = svs.components.tipsen.engine.constants;
const {
  selectIsTipsinfoSupported
} = svs.components.tipsen.engine.selectors.engineSelectors;
const {
  selectEngineDefinition
} = svs.components.tipsen.engine.selectors;
const {
  pageTypes
} = svs.component.tipsen.renderContext.constants;
const selectTipsinfo = function (state) {
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsinfoByPageType = {};
  const entries = Object.entries(state.CouponSettings.Tipsinfo[moduleName]);
  let pageTypeState;
  switch (pageType) {
    case pageTypes.RESULT:
      pageTypeState = 'stateResult';
      break;
    case pageTypes.MY_BETS:
      pageTypeState = 'stateMyBets';
      break;
    default:
      pageTypeState = 'state';
  }
  for (const entry of entries) {
    var _entry$, _entry$2;
    if (((_entry$ = entry[1]) === null || _entry$ === void 0 ? void 0 : _entry$[pageTypeState]) === true || ((_entry$2 = entry[1]) === null || _entry$2 === void 0 ? void 0 : _entry$2[pageTypeState]) === false) {
      var _entry$3;
      tipsinfoByPageType[entry[0]] = {
        state: (_entry$3 = entry[1]) === null || _entry$3 === void 0 ? void 0 : _entry$3[pageTypeState]
      };
    }
  }
  return tipsinfoByPageType;
};
const selectAllOutcomeInfoChoices = function (state) {
  var _state$CouponSettings;
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const defaultOutcomeInfoChoises = {
    display: TipsinfoTypes.OutcomeLabel,
    displayResult: TipsinfoTypes.OutcomeLabel
  };
  const outcomeInfoChoices = (_state$CouponSettings = state.CouponSettings.OutcomeInfoChoises[moduleName]) !== null && _state$CouponSettings !== void 0 ? _state$CouponSettings : defaultOutcomeInfoChoises;
  if (!selectIsTipsinfoSupported(state, outcomeInfoChoices.display, pageType)) {
    outcomeInfoChoices.display = defaultOutcomeInfoChoises.display;
  }
  if (!selectIsTipsinfoSupported(state, outcomeInfoChoices.displayResult, pageType)) {
    outcomeInfoChoices.displayResult = defaultOutcomeInfoChoises.displayResult;
  }
  return outcomeInfoChoices;
};
const selectOutcomeInfoChoise = function (state) {
  var _state$CouponSettings2;
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsInfoName = TipsinfoTypes.OutcomeLabel;
  let pageTypeState;
  switch (pageType) {
    case pageTypes.RESULT:
      pageTypeState = 'displayResult';
      break;
    case pageTypes.MY_BETS:
      pageTypeState = 'displayMyBets';
      break;
    default:
      pageTypeState = 'display';
  }
  const defaultOutcomeInfoChoises = {
    display: TipsinfoTypes.OutcomeLabel
  };
  const outcomeInfoChoises = {
    display: (_state$CouponSettings2 = state.CouponSettings.Tipsinfo[moduleName][tipsInfoName][pageTypeState]) !== null && _state$CouponSettings2 !== void 0 ? _state$CouponSettings2 : defaultOutcomeInfoChoises
  };
  return outcomeInfoChoises;
};
const selectShowMinistatInfo = function (state) {
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsInfoName = TipsinfoTypes.Ministat;
  switch (pageType) {
    case pageTypes.RESULT:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].showResult;
    case pageTypes.MY_BETS:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].showMyBets;
    default:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].show;
  }
};
const selectShowMatchAnalysesInCoupon = function (state) {
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsInfoName = TipsinfoTypes.Analys;
  switch (pageType) {
    case pageTypes.RESULT:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].result;
    case pageTypes.MY_BETS:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].myBets;
    default:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].bet;
  }
};
const selectShowMatchAnalyseForecast = function (state) {
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const supportsTipsinfoAnalyse = selectIsTipsinfoSupported(state, TipsinfoTypes.Analys, pageType);
  if (!supportsTipsinfoAnalyse) {
    return false;
  }
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsInfoName = TipsinfoTypes.Analys;
  switch (pageType) {
    case pageTypes.RESULT:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].result.showAnalyseForecast || state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].result.showAll;
    case pageTypes.MY_BETS:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].myBets.showAnalyseForecast || state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].myBets.showAll;
    default:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].bet.showAnalyseForecast || state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].bet.showAll;
  }
};
const selectShowMatchAnalyseText = function (state) {
  let pageType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : pageTypes.DEFAULT;
  const supportsTipsinfoAnalyse = selectIsTipsinfoSupported(state, TipsinfoTypes.Analys, pageType);
  if (!supportsTipsinfoAnalyse) {
    return false;
  }
  const moduleName = selectEngineDefinition(state).moduleEngine;
  const tipsInfoName = TipsinfoTypes.Analys;
  switch (pageType) {
    case pageTypes.RESULT:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].result.showAll;
    case pageTypes.MY_BETS:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].myBets.showAll;
    default:
      return state.CouponSettings.Tipsinfo[moduleName][tipsInfoName].bet.showAll;
  }
};
const selectShowCompressedCoupon = state => {
  const moduleName = selectEngineDefinition(state).moduleEngine;
  return state.CouponSettings.CompressedCoupon[moduleName].show;
};
setGlobal('svs.components.tipsen.selectors', {
  selectAllOutcomeInfoChoices,
  selectShowCompressedCoupon,
  selectTipsinfo,
  selectShowMinistatInfo,
  selectShowMatchAnalysesInCoupon,
  selectShowMatchAnalyseForecast,
  selectShowMatchAnalyseText,
  selectOutcomeInfoChoise
});

 })(window);