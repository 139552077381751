(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/selectors/assets/javascripts/select-draw-event.js') >= 0) return;  svs.modules.push('/components/tipsen/selectors/assets/javascripts/select-draw-event.js');
"use strict";

const {
  selectById
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  createSelector
} = RTK;
const selectDrawEvent = createSelector(selectById, (_0, _1, event) => event, (draw, event) => draw.drawEvents[event]);
const selectDrawEventsByDrawId = (state, drawId) => state.Draws.entities[drawId].drawEvents;
const selectDrawEventDescByMatchAndEventType = (state, drawId, matchId, eventTypeId) => {
  const drawEvents = state.Draws.entities[drawId].drawEvents;
  const matchedDrawEvent = drawEvents.find(drawEvent => drawEvent.matchId === matchId && drawEvent.eventTypeId === eventTypeId);
  return matchedDrawEvent === null || matchedDrawEvent === void 0 ? void 0 : matchedDrawEvent.eventDescription;
};
setGlobal('svs.components.tipsen.selectors', {
  selectDrawEvent,
  selectDrawEventsByDrawId,
  selectDrawEventDescByMatchAndEventType
});

 })(window);